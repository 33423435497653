import React, { useState } from "react";
import { graphql } from "gatsby";
import axios from "axios";
import PageTransition from "gatsby-plugin-page-transitions";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { H2, H5 } from "../components/Typography/Typography";
import map from "../images/sf-map-contact.jpg";

const ContactPage = ({ data }) => {
  const page = data?.takeshape?.getContact || {};

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });

  const [validated, setValidated] = useState(false);

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const form = e.target;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/ef7a6b89-b250-46d3-bedc-835c822a6fe6",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(
            true,
            "Thanks for your message! We will get back to you!",
            form
          );
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    }

    setValidated(true);
  };

  return (
    <PageTransition>
      <Layout>
        <SEO title={page.title} />
        <Section className="sf-contact">
          <Container fluid>
            <Row>
              <Col lg={6} className="d-flex justify-content-center">
                <Image src={map} className="img-fluid" alt="" />
              </Col>
              <Col lg={6} className="contact-form">
                <H2>{page.formHeadline}</H2>
                <H5 className="mt-3 mb-4">{page.formDescription}</H5>

                <Form
                  onSubmit={handleOnSubmit}
                  noValidate
                  validated={validated}
                  name="contact"
                  className="contact"
                >
                  {serverState.status && (
                    <Alert
                      variant={!serverState.status.ok ? "danger" : "success"}
                    >
                      {serverState.status.msg}
                    </Alert>
                  )}
                  <Form.Row>
                    <Col>
                      <Form.Control
                        placeholder="First name"
                        name="firstName"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        First name is required.
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Control
                        placeholder="Last name"
                        name="lastName"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Last name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Row>

                  <Form.Group className="mt-3" controlId="email">
                    <Form.Control
                      type="email"
                      placeholder="E-mail address"
                      name="email"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      E-mail address is invalid.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="message">
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Message"
                      name="message"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Message is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Get in touch
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    </PageTransition>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    takeshape {
      getContact {
        title
        formHeadline
        formDescription
      }
    }
  }
`;
